body {
  background-color: #282c34;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.login-form {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 15px;
  
}

.label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333333;
}

.input {
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  width: 100% ;
  font-size: 16px;
}

.login-form .input {
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  width: 100% ;
  font-size: 16px;
}

.submit-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.error-msg {
  font-size: 24px; 
  color: #ff0000;
  margin-top: 10px;
}

.button-container {
  display: flex;
  justify-content: flex-start; 
  gap: 10px; 
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #282c34;
}
.delete-button-inline {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 0;
}

.delete-button-inline .fas.fa-trash {
  color: white;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

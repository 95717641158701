/* admin.css */
body {
  background-color: #282c34;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  overflow: hidden;
}

.admin-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  border: none;
}

.sidebar {
  width: 20%;
  background-color: #1D1D41;
  color: white;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  height: 100vh;
  transition: transform 0.3s ease-in-out;
}

.sidebar h2 {
  display: flex;
  align-items: center;
}

.sidebar img {
  width: 40px;
  height: 30px;
  margin-right: 10px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.sidebar ul li img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.sidebar ul li.active {
  background-color: #607def;
  color: #fff;
  padding: 4px;
  border-radius: 4px;
}

.content {
  width: 80%;
  padding: 20px;
  background-color: #141332;
  color: #ffffff;
  box-sizing: border-box;
  overflow-y: auto;
  height: 100vh;
  border: none;
  transition: width 0.3s ease-in-out;
}

/* 響應式設計 - 小螢幕時調整 */
@media (max-width: 768px) {
  .sidebar {
      width: 60px;
      padding: 10px;
      overflow: hidden;
  }
  .sidebar h2 {
      display: none;
  }
  .sidebar ul li {
      justify-content: center;
  }
  .sidebar ul li img {
      margin: 0;
  }
  .content {
      width: calc(100% - 60px);
  }
}

@media (max-width: 480px) {
  .admin-container {
      flex-direction: column;
  }
  .sidebar {
      width: 100%;
      height: auto;
      padding: 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
  }
  .sidebar ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
  }
  .sidebar ul li {
      margin: 5px;
  }
  .content {
      width: 100%;
      height: calc(100vh - 50px);
      padding: 10px;
  }
}
/* 確保行動裝置不顯示標題 */
@media (max-width: 768px) {
    .mobile-view .section-title {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    .mobile-view {
      width: 100vw;
      height: 100vh;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }}
  
  /* 主要容器樣式 */
  .internal-test-container {
    background-color: #11112A; /* 深色背景 */
    padding: 16px;
    overflow-y: auto;
    /* border-radius: 12px; */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    min-height: 100vh; /* 讓內容填滿畫面高度 */

    padding-top: calc(env(safe-area-inset-top, 16px) + 16px); /* 避免狀態列擋住 */
    padding-bottom: calc(env(safe-area-inset-bottom, 16px) + 16px); /* 避免 iPhone X Home Bar 擋住 */
  }
  
  /* 自訂選單 */
  @media (min-width: 769px) {
    .dropdown-wrapper {
      width: 20%;
      text-align: left; /* 確保靠左 */
      margin-bottom: 12px;
    }}
  

    @media (max-width: 1024px) {
        .dropdown-wrapper {
          width: 100%;
          text-align: center;
          margin-top: calc(env(safe-area-inset-top) + 100px); /* 自動適應瀏海 */
        }}
  

  

  
  .custom-dropdown {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background: #1E1E42;
    color: white;
    border: 2px solid #555;
    border-radius: 8px;
    outline: none;
  }
  
  /* 表格容器 */
  .table-container {
    overflow-x: auto;
    overflow-y: auto; 
    border-radius: 12px;
    background-color: #1E1E42; /* 讓表格背景稍微亮一點 */
    padding: 10px;
  }
  
  /* 自訂表格 */
  .custom-table {
    width: 100%;
    border-collapse: collapse;
    color: white;
  }
  
  /* 表頭樣式 */
  .custom-table thead {
    width: 100%;

    /* background-color: #222252; */
    color: white;
  }
  
  .custom-table th {
    padding: 12px;
    text-align: left;
  }
  
  /* 表格內容樣式 */
  .custom-table td {
    padding: 12px;
    border-bottom: 1px solid #333;
  }
  
  /* 檔案資訊 */
  .file-info {
    display: flex;
    flex-direction: column;
  }
  
  .filename {
    font-weight: bold;
  }
  
  .date {
    font-size: 12px;
    color: #bbb;
  }
  
  /* 下載按鈕 */
  .download-button {
    display: inline-block;
    background-color: #007AFF;
    color: white;
    padding: 8px 12px;
    text-decoration: none;
    border-radius: 12px;
    font-weight: bold;
  }
  
  .download-button:hover {
    background-color: #005FCC;
  }

  